/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

.c-chk{
  position:relative;
  margin:0;
  border:0;
  padding:0;
}

.c-chk__input{
  position:absolute;
  clip:rect(1px, 1px, 1px, 1px);
}

.c-chk__label{
  display:inline-block;
  position:relative;
  cursor:pointer;
  padding-left:24px;
  vertical-align:middle;
  line-height:1.42857;
  white-space:normal;
  color:#2f3941;
  font-size:14px;
  font-weight:600;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

.c-chk__label:before{
  position:absolute;
  top:10px;
  left:0;
  -webkit-transition:border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,background-image .25s ease-in-out,color .25s ease-in-out;
  transition:border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,background-image .25s ease-in-out,color .25s ease-in-out;
  margin-top:-8px;
  border:1px solid #d8dcde;
  border-radius:4px;
  background-color:#fff;
  background-repeat:no-repeat;
  background-position:50%;
  background-size:12px;
  width:16px;
  height:16px;
  box-sizing:border-box;
  color:inherit;
  content:"";
}

.c-chk__label--regular{
  font-weight:400;
}

.c-chk__hint{
  padding-left:24px;
  line-height:1.42857;
  color:#68737d;
  font-size:14px;
}

.c-chk__message{
  display:inline-block;
  padding-left:24px;
  vertical-align:middle;
  line-height:1.33333;
  color:#49545c;
  font-size:12px;
}

.c-chk__message--error,.c-chk__message--success,.c-chk__message--warning{
  background-repeat:no-repeat;
  background-position:0;
}

.c-chk__message--error{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23cc3340'%3E%3Cg fill='none' stroke='currentColor'%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3Cpath stroke-linecap='round' d='M7.5 4.5V9'/%3E%3C/g%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#cc3340;
}

.c-chk__message--success{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23038153'%3E%3Cg fill='none' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4 9l2.5 2.5 5-5'/%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3C/g%3E%3C/svg%3E");
  color:#038153;
}

.c-chk__message--warning{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23ad5e18'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M.88 13.77L7.06 1.86c.19-.36.7-.36.89 0l6.18 11.91c.17.33-.07.73-.44.73H1.32c-.37 0-.61-.4-.44-.73zM7.5 6v3.5'/%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#ad5e18;
}

.c-chk__label.is-hidden{
  padding-left:16px;
  text-indent:-100%;
  font-size:0;
}

.c-chk__label.is-hidden:before{
  top:0;
}

.is-rtl.c-chk{
  direction:rtl;
}

.is-rtl.c-chk__label:before{
  right:0;
  left:auto;
}

.is-rtl.c-chk__hint,.is-rtl.c-chk__label,.is-rtl.c-chk__message{
  direction:rtl;
  padding-right:24px;
  padding-left:0;
}

.is-rtl.c-chk__message--error,.is-rtl.c-chk__message--success,.is-rtl.c-chk__message--warning{
  background-position:100%;
  padding-left:0;
}

.is-rtl.c-chk__label.is-hidden{
  padding-right:16px;
}

.c-chk__label+.c-chk__message{
  display:block;
  margin-top:4px;
}

.c-chk .c-chk__hint{
  display:block;
}

.c-chk__input:checked~.c-chk__label:before,.c-chk__label.is-checked:before{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' color='%23fff'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 6l2 2 4-4'/%3E%3C/svg%3E");
}

.c-chk__input:indeterminate~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle):before,.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate:before{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' color='%23fff'%3E%3Cpath stroke='currentColor' stroke-linecap='round' stroke-width='2' d='M3 6h6'/%3E%3C/svg%3E");
}

.c-chk__input:checked:enabled~.c-chk__label:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label.is-checked:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate:not(.is-disabled):before,.c-chk__input:indeterminate:enabled~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle):not(.is-disabled):before{
  border-color:#1f73b7;
  background-color:#1f73b7;
}

.c-chk__input:enabled~.c-chk__label.is-hovered:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label:not(.is-disabled):hover:before{
  border-color:#1f73b7;
}

.c-chk__input:enabled~.c-chk__label.is-focused:not(.is-disabled):before{
  outline:none;
  border-color:#1f73b7;
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-chk__input:enabled~.c-chk__label.is-active:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label:not(.is-disabled):active:before{
  -webkit-transition:border-color .1s ease-in-out,background-color .1s ease-in-out,background-image .1s ease-in-out,color .1s ease-in-out;
  transition:border-color .1s ease-in-out,background-color .1s ease-in-out,background-image .1s ease-in-out,color .1s ease-in-out;
  border-color:#1f73b7;
  background-color:#cee2f2;
}

.c-chk__input:enabled:checked~.c-chk__label.is-active:not(.is-disabled):before,.c-chk__input:enabled:checked~.c-chk__label:not(.is-disabled):active:before,.c-chk__input:enabled:indeterminate~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-active:not(.is-disabled):before,.c-chk__input:enabled:indeterminate~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle):not(.is-disabled):active:before,.c-chk__input:enabled~.c-chk__label.is-checked.is-active:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label.is-checked:not(.is-disabled):active:before,.c-chk__input:enabled~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate.is-active:not(.is-disabled):before,.c-chk__input:enabled~.c-chk__label:not(.c-chk__label--radio):not(.c-chk__label--toggle).is-indeterminate:not(.is-disabled):active:before{
  border-color:#144a75;
  background-color:#144a75;
}

.c-chk__input[disabled]~.c-chk__label,.c-chk__label.is-disabled{
  cursor:default;
}

.c-chk__input[disabled]~.c-chk__label:before,.c-chk__label.is-disabled:before{
  border-color:transparent;
  box-shadow:none;
  background-color:#e9ebed;
}

.c-chk__label--radio:before{
  border-radius:50%;
}

.c-chk__input:checked~.c-chk__label.c-chk__label--radio:before,.c-chk__label.is-checked.c-chk__label--radio:before{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' color='%23fff'%3E%3Ccircle cx='6' cy='6' r='2' fill='currentColor'/%3E%3C/svg%3E");
}

.c-chk__hint--toggle,.c-chk__label--toggle,.c-chk__message--toggle{
  padding-left:48px;
}

.c-chk__message--toggle{
  background-position:26px;
}

.c-chk__label--toggle:before{
  top:0;
  -webkit-transition:box-shadow .1s ease-in-out,background-color .15s ease-in-out,background-position .15s ease-in-out,color .25s ease-in-out;
  transition:box-shadow .1s ease-in-out,background-color .15s ease-in-out,background-position .15s ease-in-out,color .25s ease-in-out;
  margin-top:0;
  border:none;
  border-radius:100px;
  background-color:#87929d;
  background-position:10%;
  background-size:14px;
  width:40px;
  height:20px;
}

.c-chk__input:checked~.c-chk__label.c-chk__label--toggle:before,.c-chk__label--toggle:before,.c-chk__label.is-checked.c-chk__label--toggle:before{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23fff'%3E%3Ccircle cx='8' cy='8' r='7' fill='currentColor'/%3E%3C/svg%3E");
}

.c-chk__input:checked~.c-chk__label.c-chk__label--toggle:before,.c-chk__label.is-checked.c-chk__label--toggle:before{
  background-position:90%;
}

.c-chk__input:enabled~.c-chk__label.is-active:not(.is-disabled).c-chk__label--toggle:before,.c-chk__input:enabled~.c-chk__label:not(.is-disabled):active.c-chk__label--toggle:before{
  background-color:#68737d;
}

.c-chk__input:enabled:checked~.c-chk__label.is-active:not(.is-disabled).c-chk__label--toggle:before,.c-chk__input:enabled:checked~.c-chk__label:not(.is-disabled):active.c-chk__label--toggle:before,.c-chk__input:enabled~.c-chk__label.is-checked.is-active:not(.is-disabled).c-chk__label--toggle:before,.c-chk__input:enabled~.c-chk__label.is-checked:not(.is-disabled):active.c-chk__label--toggle:before{
  background-color:#144a75;
}

.c-chk__input[disabled]~.c-chk__label.c-chk__label--toggle:before,.c-chk__label.is-disabled.c-chk__label--toggle:before{
  background-color:#e9ebed;
}

.c-chk__label--toggle.is-hidden{
  padding-left:40px;
  vertical-align:top;
}

.is-rtl.c-chk__hint--toggle,.is-rtl.c-chk__label--toggle,.is-rtl.c-chk__message--toggle{
  padding-right:48px;
  padding-left:0;
}

.is-rtl.c-chk__message--toggle{
  background-position:calc(100% - 26px);
}

.is-rtl.c-chk__label--toggle.is-hidden{
  padding-right:40px;
}

.is-rtl.c-chk__label--toggle:before{
  background-position:90%;
}

.c-chk__input:checked~.c-chk__label.is-rtl.c-chk__label--toggle:before,.c-chk__label.is-checked.is-rtl.c-chk__label--toggle:before{
  background-position:10%;
}

.c-range{
  margin:0;
  border:0;
  padding:0;
}

.c-range__input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  margin:0;
  outline:none;
  background-color:inherit;
  background-size:0;
  cursor:pointer;
  padding:0;
  width:100%;
  vertical-align:middle;
}

.c-range__input::-moz-range-track{
  -moz-appearance:none;
       appearance:none;
  margin:10.5px 0;
  border-radius:5px;
  border-color:transparent;
  background-color:#e9ebed;
  background-image:linear-gradient(#1f73b7, #1f73b7);
  background-repeat:repeat-y;
  background-size:0;
  width:99.8%;
  height:5px;
  color:transparent;
  box-sizing:border-box;
}

.c-range__input::-ms-track{
  appearance:none;
  margin:10.5px 0;
  border-radius:5px;
  border-color:transparent;
  background-color:#e9ebed;
  background-image:linear-gradient(#1f73b7, #1f73b7);
  background-repeat:repeat-y;
  background-size:0;
  width:99.8%;
  height:5px;
  color:transparent;
  box-sizing:border-box;
}

.c-range__input::-webkit-slider-runnable-track{
  -webkit-appearance:none;
          appearance:none;
  margin:10.5px 0;
  border-radius:5px;
  border-color:transparent;
  background-color:#e9ebed;
  background-image:-webkit-gradient(linear, left top, left bottom, from(#1f73b7), to(#1f73b7));
  background-image:linear-gradient(#1f73b7, #1f73b7);
  background-repeat:repeat-y;
  background-size:0;
  width:99.8%;
  height:5px;
  color:transparent;
  box-sizing:border-box;
}

.c-range__input::-moz-range-thumb{
  -moz-appearance:none;
       appearance:none;
  margin:-7.5px 0;
  border:3px solid #1f73b7;
  border-radius:100%;
  box-shadow:0 4px 8px 0 rgba(47, 57, 65, .24);
  background-color:#1f73b7;
  width:20px;
  height:20px;
  box-sizing:border-box;
}

.c-range__input::-ms-thumb{
  appearance:none;
  margin:-7.5px 0;
  border:3px solid #1f73b7;
  border-radius:100%;
  box-shadow:0 4px 8px 0 rgba(47, 57, 65, .24);
  background-color:#1f73b7;
  width:20px;
  height:20px;
  box-sizing:border-box;
}

.c-range__input::-webkit-slider-thumb{
  -webkit-appearance:none;
          appearance:none;
  margin:-7.5px 0;
  border:3px solid #1f73b7;
  border-radius:100%;
  box-shadow:0 4px 8px 0 rgba(47, 57, 65, .24);
  background-color:#1f73b7;
  width:20px;
  height:20px;
  box-sizing:border-box;
}

.c-range__input::-moz-range-progress{
  border-top-left-radius:5px;
  border-bottom-left-radius:5px;
  background-color:#1f73b7;
  height:5px;
}

.c-range__input::-ms-fill-lower{
  border-top-left-radius:5px;
  border-bottom-left-radius:5px;
  background-color:#1f73b7;
  height:5px;
}

.c-range__input::-moz-focus-outer{
  border:0;
}

.c-range__input::-ms-tooltip{
  display:none;
}

.c-range__input::-webkit-slider-container,.c-range__input::-webkit-slider-runnable-track{
  background-size:inherit;
}

.c-range.is-rtl,.c-range__hint.is-rtl,.c-range__input.is-rtl,.c-range__label.is-rtl,.c-range__message.is-rtl{
  direction:rtl;
}

.c-range__input.is-rtl::-moz-range-track{
  background-position:100% 100%;
}

.c-range__input.is-rtl::-ms-track{
  background-position:100% 100%;
}

.c-range__input.is-rtl::-webkit-slider-runnable-track{
  background-position:100% 100%;
}

.c-range__input.is-rtl::-moz-range-progress{
  border-top-right-radius:5px;
  border-bottom-right-radius:5px;
}

.c-range__input.is-rtl::-ms-fill-lower{
  border-top-right-radius:5px;
  border-bottom-right-radius:5px;
}

.c-range--inline{
  display:inline-block;
}

.c-range--inline .c-range__input{
  width:auto;
}

.c-range__input.is-disabled,.c-range__input[disabled]{
  cursor:default;
}

.c-range__input.is-focused:not(.is-disabled):not([disabled])::-moz-range-thumb{
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-range__input.is-focused:not(.is-disabled):not([disabled])::-ms-thumb{
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-range__input.is-focused:not(.is-disabled):not([disabled])::-webkit-slider-thumb{
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-range__input.is-active:not(.is-disabled):not([disabled])::-moz-range-thumb,.c-range__input:active:not(.is-disabled):not([disabled])::-moz-range-thumb{
  background-color:#144a75;
}

.c-range__input.is-active:not(.is-disabled):not([disabled])::-ms-thumb,.c-range__input:active:not(.is-disabled):not([disabled])::-ms-thumb{
  background-color:#144a75;
}

.c-range__input.is-active:not(.is-disabled):not([disabled])::-webkit-slider-thumb,.c-range__input:active:not(.is-disabled):not([disabled])::-webkit-slider-thumb{
  background-color:#144a75;
}

.c-range__input.is-disabled::-moz-range-track,.c-range__input[disabled]::-moz-range-track{
  background-color:#e9ebed;
  background-image:linear-gradient(#d8dcde, #d8dcde);
}

.c-range__input.is-disabled::-ms-track,.c-range__input[disabled]::-ms-track{
  background-color:#e9ebed;
  background-image:linear-gradient(#d8dcde, #d8dcde);
}

.c-range__input.is-disabled::-webkit-slider-runnable-track,.c-range__input[disabled]::-webkit-slider-runnable-track{
  background-color:#e9ebed;
  background-image:-webkit-gradient(linear, left top, left bottom, from(#d8dcde), to(#d8dcde));
  background-image:linear-gradient(#d8dcde, #d8dcde);
}

.c-range__input.is-disabled::-moz-range-thumb,.c-range__input[disabled]::-moz-range-thumb{
  border-color:#d8dcde;
  box-shadow:none;
  background-color:#d8dcde;
}

.c-range__input.is-disabled::-ms-thumb,.c-range__input[disabled]::-ms-thumb{
  border-color:#d8dcde;
  box-shadow:none;
  background-color:#d8dcde;
}

.c-range__input.is-disabled::-webkit-slider-thumb,.c-range__input[disabled]::-webkit-slider-thumb{
  border-color:#d8dcde;
  box-shadow:none;
  background-color:#d8dcde;
}

.c-range__input.is-disabled::-moz-range-progress,.c-range__input[disabled]::-moz-range-progress{
  background-color:#d8dcde;
}

.c-range__input.is-disabled::-ms-fill-lower,.c-range__input[disabled]::-ms-fill-lower{
  background-color:#d8dcde;
}

.c-range__slider{
  display:block;
  position:relative;
  z-index:0;
  cursor:pointer;
  height:26px;
}

.c-range__slider__track{
  position:absolute;
  top:50%;
  margin-top:-2.5px;
  border-radius:5px;
  background-color:#e9ebed;
  background-origin:content-box;
  background-image:-webkit-gradient(linear, left top, left bottom, from(#1f73b7), to(#1f73b7));
  background-image:linear-gradient(#1f73b7, #1f73b7);
  background-repeat:repeat-y;
  padding:0 10px;
  width:100%;
}

.c-range__slider__track__rail{
  position:relative;
  margin:0 10px 0 -10px;
  height:5px;
}

.c-range__slider__track__rail__thumb{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  position:absolute;
  top:50%;
  z-index:1;
  margin-top:-10px;
  border:3px solid #1f73b7;
  border-radius:100%;
  box-shadow:0 4px 8px 0 rgba(47, 57, 65, .24);
  background-color:#1f73b7;
  cursor:inherit;
  width:20px;
  height:20px;
  box-sizing:border-box;
  font-size:0;
}

.c-range__slider__track__rail__thumb::-moz-range-track{
  -moz-appearance:none;
       appearance:none;
}

.c-range__slider__track__rail__thumb::-ms-track{
  appearance:none;
}

.c-range__slider__track__rail__thumb::-webkit-slider-runnable-track{
  -webkit-appearance:none;
          appearance:none;
}

.c-range__slider__track__rail__thumb::-moz-range-thumb{
  -moz-appearance:none;
       appearance:none;
}

.c-range__slider__track__rail__thumb::-ms-thumb{
  appearance:none;
}

.c-range__slider__track__rail__thumb::-webkit-slider-thumb{
  -webkit-appearance:none;
          appearance:none;
}

.c-range__slider__track__rail__thumb::-ms-tooltip{
  display:none;
}

.c-range__slider__track__rail__thumb:first-of-type{
  left:0;
}

.c-range__slider__track__rail__thumb:last-of-type{
  left:100%;
}

.c-range__slider__track__rail__thumb:focus{
  outline:none;
}

.c-range__slider__track__rail__thumb.is-focused{
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-range__slider.is-active .c-range__slider__track__rail__thumb:first-of-type,.c-range__slider:not(.is-disabled) .c-range__slider__track__rail__thumb:active{
  background-color:#144a75;
}

.c-range__slider__track__rail__thumb.is-focused:active{
  box-shadow:none;
}

.c-range__slider.is-disabled{
  cursor:default;
}

.c-range__slider.is-disabled .c-range__slider__track{
  background-color:#e9ebed;
  background-image:-webkit-gradient(linear, left top, left bottom, from(#d8dcde), to(#d8dcde));
  background-image:linear-gradient(#d8dcde, #d8dcde);
}

.c-range__slider.is-disabled .c-range__slider__track__rail__thumb{
  border-color:#d8dcde;
  box-shadow:none;
  background-color:#d8dcde;
}

.c-range--inline .c-range__slider{
  display:inline-block;
  vertical-align:middle;
  width:129px;
}

.c-range__slider.is-rtl .c-range__slider__track__rail{
  margin:0 -10px 0 10px;
}

.c-range__label{
  vertical-align:middle;
  line-height:2.14286;
  color:#2f3941;
  font-size:14px;
  font-weight:600;
}

.c-range__label--regular{
  font-weight:400;
}

.c-range__label--sm{
  line-height:2;
}

.c-range__hint{
  line-height:1.42857;
  color:#68737d;
  font-size:14px;
}

.c-range__message{
  display:inline-block;
  vertical-align:middle;
  line-height:1.33333;
  color:#49545c;
  font-size:12px;
}

.c-range__message--error,.c-range__message--success,.c-range__message--warning{
  background-repeat:no-repeat;
  background-position:0;
  padding-left:20px;
}

.c-range__message--error{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23cc3340'%3E%3Cg fill='none' stroke='currentColor'%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3Cpath stroke-linecap='round' d='M7.5 4.5V9'/%3E%3C/g%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#cc3340;
}

.c-range__message--success{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23038153'%3E%3Cg fill='none' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4 9l2.5 2.5 5-5'/%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3C/g%3E%3C/svg%3E");
  color:#038153;
}

.c-range__message--warning{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23ad5e18'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M.88 13.77L7.06 1.86c.19-.36.7-.36.89 0l6.18 11.91c.17.33-.07.73-.44.73H1.32c-.37 0-.61-.4-.44-.73zM7.5 6v3.5'/%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#ad5e18;
}

.is-rtl.c-range__message--error,.is-rtl.c-range__message--success,.is-rtl.c-range__message--warning{
  background-position:100%;
  padding-right:20px;
  padding-left:0;
}

.c-range__hint+.c-range__input,.c-range__hint+.c-range__slider,.c-range__input+.c-range__hint,.c-range__slider+.c-range__hint{
  margin-top:8px;
}

.c-range__hint--sm+.c-range__input--sm,.c-range__hint--sm+.c-range__slider--sm,.c-range__input--sm+.c-range__hint--sm,.c-range__slider--sm+.c-range__hint--sm{
  margin-top:4px;
}

.c-range__label+.c-range__hint{
  margin-top:-4px;
}

.c-range__input+.c-range__message,.c-range__slider+.c-range__message{
  margin-top:8px;
}

.c-range .c-range__hint{
  display:block;
}

.c-range--inline .c-range__input+.c-range__message,.c-range--inline .c-range__slider+.c-range__message{
  margin-top:0;
}

.c-txt{
  margin:0;
  border:0;
  padding:0;
}

.c-txt__input{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  -webkit-transition:border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out;
  transition:border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out;
  outline:none;
  border:1px solid #d8dcde;
  border-radius:4px;
  background-color:#fff;
  padding:.71429em 1.14286em;
  width:100%;
  min-height:40px;
  box-sizing:border-box;
  vertical-align:middle;
  line-height:1.28571;
  color:#2f3941;
  font-family:inherit;
  font-size:14px;
}

@media screen\0 {
  .c-txt__input{ font-family:sans-serif; }
}

.c-txt__input::-ms-browse{
  border-radius:2px;
  font-size:12px;
}

.c-txt__input::-ms-clear,.c-txt__input::-ms-reveal{
  display:none;
}

.c-txt__input::-webkit-clear-button,.c-txt__input::-webkit-inner-spin-button,.c-txt__input::-webkit-search-cancel-button,.c-txt__input::-webkit-search-results-button{
  display:none;
}

.c-txt__input::-webkit-datetime-edit{
  line-height:1;
}

.c-txt__input::-webkit-input-placeholder{
  opacity:1;
  color:#c2c8cc;
}

.c-txt__input::-moz-placeholder{
  opacity:1;
  color:#c2c8cc;
}

.c-txt__input:-ms-input-placeholder{
  opacity:1;
  color:#c2c8cc;
}

.c-txt__input::-ms-input-placeholder{
  opacity:1;
  color:#c2c8cc;
}

.c-txt__input::placeholder{
  opacity:1;
  color:#c2c8cc;
}

.c-txt__input:invalid{ box-shadow:none; }

.c-txt__input[type=date],.c-txt__input[type=datetime-local],.c-txt__input[type=file],.c-txt__input[type=month],.c-txt__input[type=time],.c-txt__input[type=week]{
  max-height:40px;
}

.c-txt__input[type=file]{
  line-height:1;
}

.c-txt__input[type=file]::-ms-value{
  display:none;
}

@media screen and (min--moz-device-pixel-ratio:0){
  .c-txt__input[type=number]{ -webkit-appearance:textfield; -moz-appearance:textfield; appearance:textfield; }
}

.c-txt.is-rtl,.c-txt__hint.is-rtl,.c-txt__input.is-rtl,.c-txt__label.is-rtl,.c-txt__message.is-rtl{
  direction:rtl;
}

.c-txt__input.is-rtl::-webkit-datetime-edit{
  direction:rtl;
}

.c-txt--inline{ display:inline-block; }

.c-txt--inline .c-txt__input{ width:auto; }

@supports (-ms-ime-align:auto){
  .c-txt__input[type=color]{ padding:1px 3px; }

  .c-txt--sm__input[type=color]{ padding:0 2px; }
}

.c-txt__input::-moz-color-swatch{
  margin-top:-5px;
  margin-left:-11px;
  border:none;
  border-radius:2px;
  width:calc(100% + 22px);
  height:28px;
}

.c-txt__input::-webkit-color-swatch{
  margin:-5px -11px;
  border:none;
  border-radius:2px;
}

.c-txt__input--sm::-webkit-color-swatch{
  margin:-3px -9px;
}

.c-txt__input--sm::-moz-color-swatch{
  margin-top:-3px;
  margin-left:-9px;
  width:calc(100% + 18px);
  height:24px;
}

.c-txt__input::-webkit-color-swatch-wrapper{ padding:0; }

.c-txt__input--area{
  resize:none;
  overflow:auto;
}

.c-txt__input--area.is-resizable{
  resize:vertical;
}

.c-txt__input--select{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none;
  position:relative;
  padding-right:2.85714em;
  text-align:left;
}

.c-txt__input--select:not(select):before{
  position:absolute;
  top:0;
  right:0;
  cursor:pointer;
  width:40px;
  height:40px;
  content:"";
}

.c-txt__input--select:not(select):before,select.c-txt__input--select{
  -webkit-transition:background-image .25s ease-in-out,border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out,-webkit-transform .25s ease-in-out;
  transition:background-image .25s ease-in-out,border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out,-webkit-transform .25s ease-in-out;
  transition:background-image .25s ease-in-out,transform .25s ease-in-out,border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out;
  transition:background-image .25s ease-in-out,transform .25s ease-in-out,border-color .25s ease-in-out,box-shadow .1s ease-in-out,background-color .25s ease-in-out,color .25s ease-in-out,-webkit-transform .25s ease-in-out;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2368737d'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E");
  background-repeat:no-repeat;
  background-position:right .85714em center;
}

select.c-txt__input--select::-ms-expand{
  display:none;
}

select.c-txt__input--select::-ms-value{
  background-color:transparent;
  color:inherit;
}

select.c-txt__input--select:-moz-focusring{
  -moz-transition:none;
  transition:none;
  text-shadow:0 0 0 #2f3941;
  color:transparent;
}

.c-txt__input--select::-webkit-calendar-picker-indicator{
  position:absolute;
  top:0;
  right:0;
  background-color:transparent;
  cursor:pointer;
  padding:0;
  width:40px;
  height:40px;
  color:transparent;
  content:"";
}

.c-txt__input--select.is-focused:not(.is-disabled):not([disabled]):not(select):before,.c-txt__input--select.is-hovered:not(.is-disabled):not([disabled]):not(select):before,.c-txt__input--select:focus:not(.is-disabled):not([disabled]):not(select):before,.c-txt__input--select:hover:not(.is-disabled):not([disabled]):not(select):before,select.c-txt__input--select.is-focused:not(.is-disabled):not([disabled]),select.c-txt__input--select.is-hovered:not(.is-disabled):not([disabled]),select.c-txt__input--select:focus:not(.is-disabled):not([disabled]),select.c-txt__input--select:hover:not(.is-disabled):not([disabled]){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%2349545c'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E");
}

.c-txt__input--select.is-open:not(.is-disabled):not([disabled]):not(select):before{
  -webkit-transform:rotate(180deg)
    translateY(-1px);
          transform:rotate(180deg)
    translateY(-1px);
}

.c-txt__input--select.is-disabled:not(select):before,.c-txt__input--select[disabled]:not(select):before,select.c-txt__input--select[disabled]{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23c2c8cc'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M4 6.5l3.6 3.6c.2.2.5.2.7 0L12 6.5'/%3E%3C/svg%3E");
  cursor:default;
}

.is-rtl.c-txt__input--select{
  padding-right:1.14286em;
  padding-left:2.85714em;
  text-align:right;
}

.is-rtl.c-txt__input--select:not(select):before{
  right:auto;
  left:0;
}

.is-rtl.c-txt__input--select::-webkit-calendar-picker-indicator{
  right:auto;
  left:0;
}

.is-rtl.c-txt__input--select:not(select):before,select.is-rtl.c-txt__input--select{
  background-position:left .85714em center;
}

.is-rtl.c-txt__input--select.is-open:not(.is-disabled):not([disabled]):not(select):before{
  -webkit-transform:rotate(-180deg)
    translateY(-1px);
          transform:rotate(-180deg)
    translateY(-1px);
}

.c-txt__input--tag .c-label,.c-txt__input--tag .c-tag{
  margin:2px;
}

.c-txt__input.c-txt__input--tag{
  display:-webkit-box;
  display:flex;
  flex-wrap:wrap;
  padding:2px 8px;
}

.c-txt__input--tag.c-txt__input--sm{
  padding-top:3px;
  padding-bottom:3px;
}

.c-txt__input--tag.c-txt__input--select{
  padding-right:2.85714em;
}

.is-rtl.c-txt__input.c-txt__input--tag.c-txt__input--select{
  padding-right:8px;
  padding-left:2.85714em;
}

.c-txt__input.is-hovered:not(.is-disabled):not([disabled]),.c-txt__input:hover:not(.is-disabled):not([disabled]){
  border-color:#5293c7;
}

.c-txt__input:focus{
  outline:none;
}

.c-txt__input.is-focused:not(.is-disabled):not([disabled]),.c-txt__input:focus:not(.is-disabled):not([disabled]){
  border-color:#1f73b7;
  box-shadow:0 0 0 3px rgba(31, 115, 183, .35);
}

.c-txt__input--focus-inset.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset:focus:not(.is-disabled):not([disabled]){
  box-shadow:inset 0 0 0 3px rgba(31, 115, 183, .35);
}

.c-txt__input.is-disabled,.c-txt__input[disabled]{
  border-color:#e9ebed;
  box-shadow:none;
  background-color:#f8f9f9;
  cursor:default;
  color:#c2c8cc;
}

.c-txt__input.is-disabled::-webkit-input-placeholder,.c-txt__input[disabled]::-webkit-input-placeholder{
  color:#c2c8cc;
}

.c-txt__input.is-disabled::-moz-placeholder,.c-txt__input[disabled]::-moz-placeholder{
  color:#c2c8cc;
}

.c-txt__input.is-disabled:-ms-input-placeholder,.c-txt__input[disabled]:-ms-input-placeholder{
  color:#c2c8cc;
}

.c-txt__input.is-disabled::-ms-input-placeholder,.c-txt__input[disabled]::-ms-input-placeholder{
  color:#c2c8cc;
}

.c-txt__input.is-disabled::placeholder,.c-txt__input[disabled]::placeholder{
  color:#c2c8cc;
}

.c-txt__input.c-txt__input--error:not(.is-disabled):not([disabled]){
  border-color:#e35b66;
}

.c-txt__input--error.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--error:focus:not(.is-disabled):not([disabled]){
  box-shadow:0 0 0 3px rgba(204, 51, 64, .35);
}

.c-txt__input--focus-inset.c-txt__input--error.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--error:focus:not(.is-disabled):not([disabled]){
  box-shadow:inset 0 0 0 3px rgba(204, 51, 64, .35);
}

.c-txt__input.c-txt__input--success:not(.is-disabled):not([disabled]){
  border-color:#5eae91;
}

.c-txt__input--success.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--success:focus:not(.is-disabled):not([disabled]){
  box-shadow:0 0 0 3px rgba(3, 129, 83, .35);
}

.c-txt__input--focus-inset.c-txt__input--success.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--success:focus:not(.is-disabled):not([disabled]){
  box-shadow:inset 0 0 0 3px rgba(3, 129, 83, .35);
}

.c-txt__input.c-txt__input--warning:not(.is-disabled):not([disabled]){
  border-color:#ffb648;
}

.c-txt__input--warning.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--warning:focus:not(.is-disabled):not([disabled]){
  box-shadow:0 0 0 3px rgba(237, 150, 28, .35);
}

.c-txt__input--focus-inset.c-txt__input--warning.is-focused:not(.is-disabled):not([disabled]),.c-txt__input--focus-inset.c-txt__input--warning:focus:not(.is-disabled):not([disabled]){
  box-shadow:inset 0 0 0 3px rgba(237, 150, 28, .35);
}

.c-txt__input--sm{
  padding:.42857em .85714em;
  min-height:32px;
}

.c-txt__input--sm[type=date],.c-txt__input--sm[type=datetime-local],.c-txt__input--sm[type=file],.c-txt__input--sm[type=month],.c-txt__input--sm[type=time],.c-txt__input--sm[type=week]{
  max-height:32px;
}

.c-txt__input--sm::-ms-browse{
  font-size:11px;
}

.c-txt__input--sm.c-txt__input--select{
  padding-right:2.28571em;
}

.c-txt__input--sm.c-txt__input--select:not(select):before{
  width:32px;
}

.c-txt__input--sm.c-txt__input--select:not(select):before,select.c-txt__input--sm.c-txt__input--select{
  background-position:right .71429em center;
  background-size:12px;
  height:32px;
}

.c-txt__input--sm.c-txt__input--select::-webkit-calendar-picker-indicator{
  width:32px;
  height:32px;
}

.is-rtl.c-txt__input--sm.c-txt__input--select{
  padding-right:.85714em;
  padding-left:2.28571em;
}

.is-rtl.c-txt__input--sm.c-txt__input--select:not(select):before,select.is-rtl.c-txt__input--sm.c-txt__input--select{
  background-position:left .71429em center;
}

.c-txt__input--sm .c-txt__input--media__figure:first-child,.is-rtl.c-txt__input--sm .c-txt__input--media__figure:last-child{
  margin:auto .64285em auto 0;
}

.c-txt__input--sm .c-txt__input--media__figure:last-child,.is-rtl.c-txt__input--sm .c-txt__input--media__figure:first-child{
  margin:auto 0 auto .64285em;
}

.c-txt__input.c-txt__input--bare{
  border:none;
  border-radius:0;
  background-color:transparent;
  padding:0;
  min-height:1em;
}

.c-txt__input.is-focused:not(.is-disabled):not([disabled]).c-txt__input--bare,.c-txt__input:focus:not(.is-disabled):not([disabled]).c-txt__input--bare{
  box-shadow:none;
}

.c-txt__input.is-disabled.c-txt__input--bare,.c-txt__input[disabled].c-txt__input--bare{
  background-color:transparent;
}

.c-txt__input--select.c-txt__input--bare:not(select):before{
  height:1.28571em;
}

.c-txt__input--select.c-txt__input--bare::-webkit-calendar-picker-indicator{
  height:1.28571em;
}

.c-txt__input--media{
  display:-webkit-box;
  display:flex;
  -webkit-box-align:baseline;
          align-items:baseline;
  cursor:text;
}

.c-txt__input--media__body{
  -webkit-box-flex:1;
          flex-grow:1;
}

.c-txt__input--media__figure{
  margin:auto;
  max-height:16px;
  color:#c2c8cc;
}

.c-txt__input--media__figure svg{
  width:16px;
  height:16px;
}

.c-txt__input--sm .c-txt__input--media__figure svg{
  width:12px;
  height:12px;
}

.c-txt__input--media__figure:first-child,.is-rtl .c-txt__input--media__figure:last-child{
  margin-right:.85715em;
  margin-left:0;
}

.c-txt__input--media__figure:last-child,.is-rtl .c-txt__input--media__figure:first-child{
  margin-right:0;
  margin-left:.85715em;
}

.c-txt__label{
  vertical-align:middle;
  line-height:2.14286;
  color:#2f3941;
  font-size:14px;
  font-weight:600;
}

.c-txt__label--regular{
  font-weight:400;
}

.c-txt__label--sm{
  line-height:2;
}

.c-txt__hint{
  line-height:1.42857;
  color:#68737d;
  font-size:14px;
}

.c-txt__message{
  display:inline-block;
  vertical-align:middle;
  line-height:1.33333;
  color:#49545c;
  font-size:12px;
}

.c-txt__message--error,.c-txt__message--success,.c-txt__message--warning{
  background-repeat:no-repeat;
  background-position:0;
  padding-left:20px;
}

.c-txt__message--error{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23cc3340'%3E%3Cg fill='none' stroke='currentColor'%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3Cpath stroke-linecap='round' d='M7.5 4.5V9'/%3E%3C/g%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#cc3340;
}

.c-txt__message--success{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23038153'%3E%3Cg fill='none' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4 9l2.5 2.5 5-5'/%3E%3Ccircle cx='7.5' cy='8.5' r='7'/%3E%3C/g%3E%3C/svg%3E");
  color:#038153;
}

.c-txt__message--warning{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' color='%23ad5e18'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' d='M.88 13.77L7.06 1.86c.19-.36.7-.36.89 0l6.18 11.91c.17.33-.07.73-.44.73H1.32c-.37 0-.61-.4-.44-.73zM7.5 6v3.5'/%3E%3Ccircle cx='7.5' cy='12' r='1' fill='currentColor'/%3E%3C/svg%3E");
  color:#ad5e18;
}

.is-rtl.c-txt__message--error,.is-rtl.c-txt__message--success,.is-rtl.c-txt__message--warning{
  background-position:100%;
  padding-right:20px;
  padding-left:0;
}

.c-txt__hint+.c-txt__input,.c-txt__input+.c-txt__hint{
  margin-top:8px;
}

.c-txt__hint--sm+.c-txt__input--sm,.c-txt__input--sm+.c-txt__hint--sm{
  margin-top:4px;
}

.c-txt__label+.c-txt__hint{
  margin-top:-4px;
}

.c-txt__input+.c-txt__message{
  margin-top:8px;
}

.c-txt .c-txt__hint{
  display:block;
}

.c-txt--inline .c-txt__input+.c-txt__message{
  margin-top:0;
}
